import SqrlErr, { ParseErr } from './err'
import { trimWS } from './utils'

/* TYPES */

import { SqrlConfig } from './config'

export type TagType = '~' | '/' | '#' | '?' | 'r' | '!' | 's'
export type TemplateAttribute = 'c' | 'f' | 'fp' | 'p' | 'n' | 'res' | 'err'
export type TemplateObjectAttribute = 'c' | 'p' | 'n' | 'res'

export type AstObject = string | TemplateObject

export type Filter = [string, string] | [string, string, true]
// [name, params, async]
export interface TemplateObject {
  n?: string
  t?: string
  f: Array<Filter>
  c?: string
  p?: string
  res?: string
  d?: Array<AstObject>
  raw?: boolean
  a?: boolean // async
  b?: Array<ParentTemplateObject>
}

export interface ParentTemplateObject extends TemplateObject {
  d: Array<AstObject>
  b: Array<ParentTemplateObject>
}

/* END TYPES */

var asyncRegExp = /^async +/

var templateLitReg = /`(?:\\[\s\S]|\${(?:[^{}]|{(?:[^{}]|{[^}]*})*})*}|(?!\${)[^\\`])*`/g

var singleQuoteReg = /'(?:\\[\s\w"'\\`]|[^\n\r'\\])*?'/g

var doubleQuoteReg = /"(?:\\[\s\w"'\\`]|[^\n\r"\\])*?"/g

export default function parse (str: string, env: SqrlConfig): Array<AstObject> {
  templateLitReg.lastIndex = 0
  singleQuoteReg.lastIndex = 0
  doubleQuoteReg.lastIndex = 0

  var parseCloseReg = new RegExp(
    '([|()]|=>)|' + // powerchars
    '\'|"|`|\\/\\*|\\s*((\\/)?(-|_)?' + // comments, strings
      env.tags[1] +
      ')',
    'g'
  )

  var tagOpenReg = new RegExp('([^]*?)' + env.tags[0] + '(-|_)?\\s*', 'g')
  var startInd = 0
  var trimNextLeftWs: string | false = false

  function parseTag (tagOpenIndex: number): TemplateObject {
    var currentObj: TemplateObject = { f: [] }
    var numParens = 0
    var firstChar = str[startInd]
    var currentAttribute: TemplateAttribute = 'c' // default - Valid values: 'c'=content, 'f'=filter, 'fp'=filter params, 'p'=param, 'n'=name
    var currentType: TagType = 'r' // Default
    startInd += 1 // assume we're gonna skip the first character

    if (firstChar === '~' || firstChar === '#' || firstChar === '/') {
      currentAttribute = 'n'
      currentType = firstChar
    } else if (firstChar === '!' || firstChar === '?') {
      // ? for custom
      currentType = firstChar
    } else if (firstChar === '*') {
      currentObj.raw = true
    } else {
      startInd -= 1
    }

    function addAttrValue (indx: number) {
      var valUnprocessed = str.slice(startInd, indx)
      // console.log(valUnprocessed)
      var val = valUnprocessed.trim()
      if (currentAttribute === 'f') {
        if (val === 'safe') {
          currentObj.raw = true
        } else {
          if (env.async && asyncRegExp.test(val)) {
            val = val.replace(asyncRegExp, '')
            currentObj.f.push([val, '', true])
          } else {
            currentObj.f.push([val, ''])
          }
        }
      } else if (currentAttribute === 'fp') {
        currentObj.f[currentObj.f.length - 1][1] += val
      } else if (currentAttribute === 'err') {
        if (val) {
          var found = valUnprocessed.search(/\S/)
          ParseErr('invalid syntax', str, startInd + found)
        }
      } else {
        // if (currentObj[currentAttribute]) { // TODO make sure no errs
        //   currentObj[currentAttribute] += val
        // } else {
        currentObj[currentAttribute] = val
        // }
      }
      startInd = indx + 1
    }

    parseCloseReg.lastIndex = startInd

    var m
    // tslint:disable-next-line:no-conditional-assignment
    while ((m = parseCloseReg.exec(str)) !== null) {
      var char = m[1]
      var tagClose = m[2]
      var slash = m[3]
      var wsControl = m[4]
      var i = m.index

      if (char) {
        // Power character
        if (char === '(') {
          if (numParens === 0) {
            if (currentAttribute === 'n') {
              addAttrValue(i)
              currentAttribute = 'p'
            } else if (currentAttribute === 'f') {
              addAttrValue(i)
              currentAttribute = 'fp'
            }
          }
          numParens++
        } else if (char === ')') {
          numParens--
          if (numParens === 0 && currentAttribute !== 'c') {
            // Then it's closing a filter, block, or helper
            addAttrValue(i)

            currentAttribute = 'err' // Reset the current attribute
          }
        } else if (numParens === 0 && char === '|') {
          addAttrValue(i) // this should actually always be whitespace or empty
          currentAttribute = 'f'
        } else if (char === '=>') {
          addAttrValue(i)
          startInd += 1 // this is 2 chars
          currentAttribute = 'res'
        }
      } else if (tagClose) {
        addAttrValue(i)
        startInd = i + m[0].length
        tagOpenReg.lastIndex = startInd
        // console.log('tagClose: ' + startInd)
        trimNextLeftWs = wsControl
        if (slash && currentType === '~') {
          currentType = 's'
        } // TODO throw err
        currentObj.t = currentType
        return currentObj
      } else {
        var punctuator = m[0]
        if (punctuator === '/*') {
          var commentCloseInd = str.indexOf('*/', parseCloseReg.lastIndex)

          if (commentCloseInd === -1) {
            ParseErr('unclosed comment', str, m.index)
          }
          parseCloseReg.lastIndex = commentCloseInd
        } else if (punctuator === "'") {
          singleQuoteReg.lastIndex = m.index

          var singleQuoteMatch = singleQuoteReg.exec(str)
          if (singleQuoteMatch) {
            parseCloseReg.lastIndex = singleQuoteReg.lastIndex
          } else {
            ParseErr('unclosed string', str, m.index)
          }
        } else if (punctuator === '"') {
          doubleQuoteReg.lastIndex = m.index
          var doubleQuoteMatch = doubleQuoteReg.exec(str)

          if (doubleQuoteMatch) {
            parseCloseReg.lastIndex = doubleQuoteReg.lastIndex
          } else {
            ParseErr('unclosed string', str, m.index)
          }
        } else if (punctuator === '`') {
          templateLitReg.lastIndex = m.index
          var templateLitMatch = templateLitReg.exec(str)
          if (templateLitMatch) {
            parseCloseReg.lastIndex = templateLitReg.lastIndex
          } else {
            ParseErr('unclosed string', str, m.index)
          }
        }
      }
    }
    // TODO: Do I need this?
    ParseErr('unclosed tag', str, tagOpenIndex)
    return currentObj // To prevent TypeScript from erroring
  }

  function parseContext (parentObj: TemplateObject, firstParse?: boolean): ParentTemplateObject {
    parentObj.b = [] // assume there will be blocks // TODO: perf optimize this
    parentObj.d = []
    var lastBlock: ParentTemplateObject | false = false
    var buffer: Array<AstObject> = []

    function pushString (strng: string, shouldTrimRightOfString?: string | false) {
      if (strng) {
        // if string is truthy it must be of type 'string'

        // TODO: benchmark replace( /(\\|')/g, '\\$1')
        strng = trimWS(
          strng,
          env,
          trimNextLeftWs, // this will only be false on the first str, the next ones will be null or undefined
          shouldTrimRightOfString
        )

        if (strng) {
          // replace \ with \\, ' with \'

          strng = strng.replace(/\\|'/g, '\\$&').replace(/\r\n|\n|\r/g, '\\n')
          // we're going to convert all CRLF to LF so it doesn't take more than one replace

          buffer.push(strng)
        }
      }
    }

    // Random TODO: parentObj.b doesn't need to have t: #
    var tagOpenMatch
    // tslint:disable-next-line:no-conditional-assignment
    while ((tagOpenMatch = tagOpenReg.exec(str)) !== null) {
      var precedingString = tagOpenMatch[1]
      var shouldTrimRightPrecedingString = tagOpenMatch[2]

      pushString(precedingString, shouldTrimRightPrecedingString)
      startInd = tagOpenMatch.index + tagOpenMatch[0].length

      var currentObj = parseTag(tagOpenMatch.index)
      // ===== NOW ADD THE OBJECT TO OUR BUFFER =====

      var currentType = currentObj.t
      if (currentType === '~') {
        var hName = currentObj.n || ''
        if (env.async && asyncRegExp.test(hName)) {
          currentObj.a = true
          currentObj.n = hName.replace(asyncRegExp, '')
        }
        currentObj = parseContext(currentObj) // currentObj is the parent object
        buffer.push(currentObj)
      } else if (currentType === '/') {
        if (parentObj.n === currentObj.n) {
          if (lastBlock) {
            // If there's a previous block
            lastBlock.d = buffer
            parentObj.b.push(lastBlock)
          } else {
            parentObj.d = buffer
          }
          // console.log('parentObj: ' + JSON.stringify(parentObj))
          return parentObj as ParentTemplateObject
        } else {
          ParseErr(
            "Helper start and end don't match",
            str,
            tagOpenMatch.index + tagOpenMatch[0].length
          )
        }
      } else if (currentType === '#') {
        // TODO: make sure async stuff inside blocks are recognized
        if (lastBlock) {
          // If there's a previous block
          lastBlock.d = buffer
          parentObj.b.push(lastBlock)
        } else {
          parentObj.d = buffer
        }

        var blockName = currentObj.n || ''
        if (env.async && asyncRegExp.test(blockName)) {
          currentObj.a = true
          currentObj.n = blockName.replace(asyncRegExp, '')
        }

        lastBlock = currentObj as ParentTemplateObject // Set the 'lastBlock' object to the value of the current block

        buffer = []
      } else if (currentType === 's') {
        var selfClosingHName = currentObj.n || ''
        if (env.async && asyncRegExp.test(selfClosingHName)) {
          currentObj.a = true
          currentObj.n = selfClosingHName.replace(asyncRegExp, '')
        }
        buffer.push(currentObj)
      } else {
        buffer.push(currentObj)
      }
      // ===== DONE ADDING OBJECT TO BUFFER =====
    }

    if (firstParse) {
      pushString(str.slice(startInd, str.length), false)
      parentObj.d = buffer
    } else {
      throw SqrlErr('unclosed helper "' + parentObj.n + '"')
      // It should have returned by now
    }

    return parentObj as ParentTemplateObject
  }

  var parseResult = parseContext({ f: [] }, true)
  // console.log(JSON.stringify(parseResult))
  if (env.plugins) {
    for (var i = 0; i < env.plugins.length; i++) {
      var plugin = env.plugins[i]
      if (plugin.processAST) {
        parseResult.d = plugin.processAST(parseResult.d, env)
      }
    }
  }
  return parseResult.d // Parse the very outside context
}
